import React, { useState, useEffect, useRef, useCallback } from "react";
import Grid from "@material-ui/core/Grid";
import { Timestamp } from "firebase/firestore";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useAcceptedAccountList, useShareRequestsBeaconsList } from "../util/db";
import { InviteReportDemographicsTile } from "./InviteReportDemographicsTile";
import { InviteReportDemographicsTileRecorded } from "./InviteReportDemographicsTileRecorded";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import ImageIcon from '@material-ui/icons/Image';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CustomCvsButton from "./CustomCvsButton";
import html2canvas from 'html2canvas';
import { InviteReportBeaconTile } from "./InviteReportBeaconTile";
import CircularProgress from '@material-ui/core/CircularProgress';

const moment = require('moment');



const useStyles = makeStyles((theme) => ({
    headline: {
      //fontWeight: "800",
      //color: "#ffffff",
    },
    doBlur: {
      filter: "blur(15px)",
    },
    noBlur: {
      //filter: "blur(15px)",
    },
    hrMargin: {
      margin: "30px",
    },
    button: {
      background: '#000000',
    },
    paper: {
        padding: '6px 16px',
      },
      secondaryTail: {
        backgroundColor: theme.palette.secondary.main,
      },
  }));

export const InviteReportBeaconSection = (props) => {
    const classes = useStyles();
    const demoRef = useRef();

    const { inviteSummary, isPaid, ...otherProps } = props;

    
    const [loading, setLoading] = useState(true);    

    const { data: beaconsList, status: resultsStatus, error: resultsError, } = useShareRequestsBeaconsList(inviteSummary.id);

    //console.log(beaconsList);



    
    useEffect(() => {
       if (!!beaconsList) {
        //console.log("SET LOADING == FALSE");
          setLoading(false)
       }

      }, [beaconsList]);



      const handleDownloadImage = async (element, name) => {
        //const element: any = overviewRef.current;
        const canvas = await html2canvas(element);
      
        const data = canvas.toDataURL('image/jpg');
        const link = document.createElement('a');
      
        if (typeof link.download === 'string') {
          link.href = data;
          link.download = name + '_image.jpg';
      
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          window.open(data);
        }
      };

      const downloadTimelineSection = useCallback(() => {
        handleDownloadImage(demoRef.current, 'SIX_beacons');


      }, [inviteSummary]);




    return (

      
        <>
        { !loading && beaconsList.length >= 1 && 

        <Grid container spacing={5} alignItems="stretch" style={{marginTop: '50px'}} ref={demoRef} className={isPaid === true ? classes.noBlur : classes.doBlur}>

        <Grid item xs={8} md={6} >
            <Typography variant={`h4`} color="textPrimary" className={classes.headline} gutterBottom={false} >
                BEACONS
            </Typography>
        </Grid>

        <Grid item xs={4} md={6} style={{display: 'grid', justifyItems: "right"}} >
              <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">

                  <Button variant="contained" color="secondary" size="medium" className={classes.button} disabled={isPaid ? false : true}>
                      <ImageIcon onClick={downloadTimelineSection}/> 
                  </Button>

                 {/* <CustomCvsButton variant="contained" color="secondary" data={genderMap} filename={`SIX_demo_export_${moment().format('YYYYMMDD_HH-mm-ss')}.csv`} delimiter="," disabled={isPaid ? false : true} size="medium" className={classes.button}>
                      <CloudDownloadIcon />
                  </CustomCvsButton>
                 */}

                </ButtonGroup>
        </Grid>

        { loading && 
          <Grid item xs={12} md={12} >
              <CircularProgress />
          </Grid>
        }

{ !loading && beaconsList.length === 0 && 
  <Grid item xs={12} md={12} >
    <Typography variant="h6"  className={classes.headline} gutterBottom={false}>
    No beacons found for this experience.
    </Typography>
  </Grid>
}

{ !loading && beaconsList.length >= 1 && 

        <Grid item xs={12} md={12} >

        <Grid container={true} spacing={3}>

        { beaconsList.map((beaconItem, index) => 
               

              <InviteReportBeaconTile key={beaconItem.id} inviteSummary={inviteSummary} beaconItem={beaconItem} isPaid={isPaid} color={'#4893FF'}/>

        )}


        </Grid>

        </Grid>
}
         



        </Grid>
}
        </>
    )

};