import React from "react";
import Meta from "./../components/Meta";
import InviteReportSection from "./../components/InviteReportSection";
import { useRouter } from "./../util/router";
import ReportFooter from "./../components/ReportFooter";


function InviteReportPage(props) {
  const router = useRouter();
  const currentYear = new Date().getFullYear();

   
  return (
    <>
      <Meta title="Invite Report" />

     

        <InviteReportSection
          bgColor="#ffffffff"
          size="normal"
          bgImage=""
          bgImageOpacity={1}
          title="Your Team"
          subtitle=""
          inviteId={router.query.share}
          ownerId={router.query.owner}
        />
      

      <ReportFooter
                bgColor="darkblue"
                size="medium"
                bgImage=""
                bgImageOpacity={1}
                description="SIX Key Moments unlock radical fulfillment for teams. Powered by Value Measurement and the science of Immersion Neuroscience Inc."
                copyright={`© ${new Date().getFullYear()} Immersion Neuroscience, Inc. All Rights Reserved.`}
                logo="/images/SIX_Wordmark_Gradient.svg"
                logoInverted="/images/SIX_Wordmark_Gradient.svg"
                sticky={true}
              />



    </>
  );
}


export default InviteReportPage;