import React, { useState, useEffect, useRef, useCallback } from "react";
import { InfluxDB } from "@influxdata/influxdb-client";
import { ResponsiveLine } from "@nivo/line";
import { Timestamp } from "firebase/firestore";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useRouter } from "./../util/router";
import { useAuth } from "./../util/auth";
import Grid from "@material-ui/core/Grid";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import { ShareRequestResultsTimeline } from "./ShareRequestResultsTimeline";
import { ShareRequestResultsSummary } from "./ShareRequestResultsSummary";
import { redirectToShareResultCheckout } from "./../util/stripe";
import { ShareRequestDemoLive } from "./ShareRequestDemoLive"; 
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useShareRequestsAcceptedList } from "../util/db";
import ImageIcon from '@material-ui/icons/Image';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CustomCvsButton from "./CustomCvsButton";
import html2canvas from 'html2canvas';
import { apiRequestWithKey } from "./../util/util"
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Paper from '@material-ui/core/Paper';
import TimelineResultIcon from "./immersion/TimelineResultIcon";
import CircularProgress from '@material-ui/core/CircularProgress';
import { useMediaQuery } from "@material-ui/core";


const moment = require('moment');

const useStyles = makeStyles((theme) => ({
    headline: {
      //fontWeight: "800",
      //color: "#ffffff",
    },
    doBlur: {
      filter: "blur(15px)",
    },
    noBlur: {
      //filter: "blur(15px)",
    },
    hrMargin: {
      margin: "30px",
    },
    button: {
      background: '#000000',
    },
    superwide: {
        width: "15000px",
    },
    paper: {
        padding: '6px 16px',
        background: '#F5F5F5',
        marginTop: '12px',
      },
      secondaryTail: {
        backgroundColor: theme.palette.secondary.main,
      },
  }));


  export const InviteReportDetailSection = (props) => {
    const classes = useStyles();
    const detailRef = useRef();
    const timelineRef = useRef();
    const { inviteSummary, legend, isPaid, ...otherProps } = props;

    const [data, setData] = useState([]);
    const [graphData, setGraphData] = useState([]);

    const [aggWindow, setAggWindow] = useState("15m");
    const [chartWidth, setChartWidth] = useState("100%");

    const [isLoadingData, setIsLoadingData] = useState(true);
    const [inviteDataError, setInviteDataError] = useState("");

    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("xs"));

    useEffect(() => {

        if (!!inviteSummary) {
            async function fetchData() {
                setIsLoadingData(true);
                const tempAgg = inviteSummary.duration_seconds > 3600 ? "30m" : inviteSummary.duration_seconds < 600 ? "1m" : "5m";
                setAggWindow(tempAgg);

                let url = `query-event?id=${inviteSummary.id}&agg=${tempAgg}`;
                //console.log(url);
                try {
                    if (inviteSummary.audience.completed > 0) {
                        const temp = await apiRequestWithKey(url);  //get inviteSummary from API
                        //console.log(temp);
                        setData(temp);
                        const newChartData = formatDataForGraph(inviteSummary.type, temp);
                        setGraphData(newChartData);


                        if (!!newChartData) {
                            if (newChartData.length > 50) {
                                let c = newChartData.length * 12;
                                setChartWidth(c + "px");
                                
                            }
                        } else {
                            setChartWidth("100%");
                        }


                    }
                    setIsLoadingData(false);
                } catch (error) {
                    if (error != undefined) {
                        console.log(error);
                        setInviteDataError(error.message);
                    }
                    setIsLoadingData(false);
                }
              }
              fetchData();

        }
    },[]);


    const formatDataForGraph = (type, inboundData) => {
        let engagement = [];
        let safety = [];
        let finalData = []

        inboundData.forEach(row => {
            engagement.push({ "x": row.ts, "y" : Math.round(row.value) });
            safety.push({ "x" : row.ts, "y" : Math.round(row.safety) });
        });

        finalData.push({"id": "Value Score", "data" : engagement});
        finalData.push({"id": "Safety", "data" : safety});

        return finalData;

    }


    const formatDataForTimeline = (inboundData) => {
        let finalData = []

        var i = -1;
        inboundData.forEach(row => {
            i = i + 1;
            var endDate = moment.unix(row.ts).toDate();
            var endMoment =  moment.unix(row.ts);

            var startDate = endDate

            if (aggWindow === "1m") {
                startDate = endMoment.subtract(1, "minutes").toDate();
            } else if (aggWindow === "5m") {
                startDate = endMoment.subtract(5, "minutes").toDate();
            } else if (aggWindow === "15m") {
                startDate = endMoment.subtract(15, "minutes").toDate();
            } else if (aggWindow === "30m") {
                startDate = endMoment.subtract(30, "minutes").toDate();
            } else if (aggWindow === "1h") {
                startDate = endMoment.subtract(60, "minutes").toDate();
            }

            let dataObject = {"id": i, "start": startDate, "end": endDate, "value": Math.round(row.value), "safety": Math.round(row.safety)};
            //console.log(JSON.stringify(dataObject))
            finalData.push(dataObject);
        });
        return finalData;
    }

    const changeView = async (newAgg) => {
        setAggWindow(newAgg);
        //TODO:  update the data query
        setIsLoadingData(true);

        let url = `query-event?id=${inviteSummary.id}&agg=${newAgg}`;
        //console.log(`New Agg Query: ${url}`);

        try {
            if (inviteSummary.audience.completed > 0) {
                const temp = await apiRequestWithKey(url);  //get inviteSummary from API
                //console.log(temp);
                setData(temp);
                
                const newChartData = formatDataForGraph(inviteSummary.type, temp);
                setGraphData(newChartData);


                if (!!newChartData) {
                    if (newChartData.length > 50) {
                        let c = newChartData.length * 12;
                        //console.log(`setting chart width to: ${c} px`);
                        setChartWidth(c + "px");
                        
                    }
                } else {
                    //console.log(`setting chart width to: 100%`);
                    setChartWidth("100%");
                }



            }
            setIsLoadingData(false);
        } catch (error) {
            if (error != undefined) {
                console.log(error);
                setInviteDataError(error.message);
            }
            setIsLoadingData(false);
        }



      }

      const handleDownloadImage = async (element, name) => {
        //const element: any = overviewRef.current;
        const canvas = await html2canvas(element);
      
        const data = canvas.toDataURL('image/jpg');
        const link = document.createElement('a');
      
        if (typeof link.download === 'string') {
          link.href = data;
          link.download = name + '_image.jpg';
      
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          window.open(data);
        }
      };


const getChartWidth = () => {
    return chartWidth;
}


      const downloadDetailSection = useCallback(() => {
        handleDownloadImage(detailRef.current, 'SIX_detail');
      }, [inviteSummary]);

      const downloadTimelineSection = useCallback(() => {
        handleDownloadImage(timelineRef.current, 'SIX_timeline');
      }, [inviteSummary]);

      const axisBottomByType = (type) => {
        if (type === "recorded") {
            return {
                "orient": "bottom",
                "tickSize": 5,
                "tickPadding": 5,
                "tickRotation": 90,
                "format": (d) => {return d % 10 === 0 ? d : '';},
                "legend": legend,
                "legendOffset": 10,
                "legendPosition": 'middle'
              };
        } else {
            return {
                "orient": "bottom",
                "tickSize": 5,
                "tickPadding": 5,
                "tickRotation": 90,
                "format": (d) => {
                    //console.log(`xaxis => ${d}`); 
                    if (graphData[0].data.length >= 100) {
                        return d % 3 === 0 ? moment(d*1000).format('h:mm:ss a') : '';
                        //return moment(d).minute() % 2 === 0 ? moment(d*1000).format('h:mm:ss a') : '';
                        /*if (aggWindow === "1s") {
                            return moment(d).minute() % 3 === 0 ? moment(d*1000).format('h:mm:ss a') : '';
                        } else {
                            return moment(d).minute() % 3 === 0 ? moment(d*1000).format('h:mm a') : '';
                        }*/
                    } else {
                        return moment(d*1000).format('h:mm: a');
                    }
                    
                },
                "legend": legend,
                "legendOffset": 10,
                "legendPosition": 'middle'
                };
        }
      }


    return (
        <>

{  !!isPaid &&
 <>
<Grid container spacing={5} alignItems="stretch" style={{marginTop: '50px'}} ref={detailRef}>
                
<Grid item xs={4} sm={6}>
    <Typography variant={`h4`} color="textPrimary" className={classes.headlineBlack} gutterBottom={false} >
        DETAIL
    </Typography>
</Grid>

<Grid item xs={8}  sm={6} >
        <Grid container  alignContent="flex-end" alignItems="flex-end" justifyContent="flex-end">
          <Grid item={true} xs={12}  className={classes.actionBar} style={{display: 'grid', justifyItems: "right"}}>

          {!isLoadingData && !!data && inviteSummary && 

            <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group" orientation="horizontal">
            { inviteSummary.duration_seconds < 900 && 
            <Button variant="contained" color="secondary" size={isSmallScreen ? "small" : "medium"} onClick={(event) => changeView('1s')} className={classes.button} disabled={aggWindow === '1s' || isPaid === false}>
                  1s
              </Button>
            }

              <Button variant="contained" color="secondary" size={isSmallScreen ? "small" : "medium"} onClick={(event) => changeView('1m')} className={classes.button} disabled={aggWindow === '1m' || isPaid === false}>
                  1m
              </Button>
              { inviteSummary.duration_seconds > 600 && 
                <Button variant="contained" color="secondary" size={isSmallScreen ? "small" : "medium"} onClick={(event) => changeView('5m')} className={classes.button} disabled={aggWindow === '5m' || isPaid === false}>
                    5m
                </Button>
                }
              { inviteSummary.duration_seconds > 1800 && 
                <Button variant="contained" color="secondary" size={isSmallScreen ? "small" : "medium"} onClick={(event) => changeView('15m')} className={classes.button} disabled={aggWindow === '15m' || isPaid === false}>
                    15m
                </Button>
               }
               { inviteSummary.duration_seconds > 3600 && 
                <Button variant="contained" color="secondary" size={isSmallScreen ? "small" : "medium"} onClick={(event) => changeView('30m')} className={classes.button} disabled={aggWindow === '30m' || isPaid === false}>
                    30m
                </Button>
                }

                { inviteSummary.duration_seconds > 7200 && 
                <Button variant="contained" color="secondary" size={isSmallScreen ? "small" : "medium"} onClick={(event) => changeView('1h')} className={classes.button} disabled={aggWindow === '1h' || isPaid === false}>
                    1h
                </Button>
                }

                <Button variant="contained" color="secondary" size={isSmallScreen ? "small" : "medium"} className={classes.button} disabled={isPaid ? false : true}>
                    <ImageIcon onClick={downloadDetailSection}/> 
                </Button>
      
                <CustomCvsButton variant="contained" color="secondary" data={data} filename={`SIX_event_export_${aggWindow}_${moment().format('YYYYMMDD_HH-mm-ss')}.csv`} delimiter="," disabled={isPaid ? false : true} size={isSmallScreen ? "small" : "medium"} className={classes.button}>
                    <CloudDownloadIcon />
                </CustomCvsButton>
              
            </ButtonGroup>

          }

          </Grid>
        </Grid>
    </Grid>


    <Grid item xs={12} style={{height: '50vh', overflowX: "scroll"}} className={isPaid === true ? classes.noBlur : classes.doBlur}>

{!!isLoadingData &&

        <Grid container={true} className={classes.buffer}>
        <Grid item={true} xs={12} md={12} className={classes.gridItemCenter} >    
            <CircularProgress />
            <Typography variant="h6">Loading Details...</Typography>
        </Grid>
        </Grid>

}


{  inviteSummary && graphData && !isLoadingData && 

<div style={{height: "50vh", width: graphData[0].data.length >= 100 ? graphData[0].data.length * 16 + "px" : "99%" }}>

    <ResponsiveLine 
data={graphData}
margin={{ top: 50, right: 50, bottom: 100, left: 50 }}
//colors={{ scheme: 'accent' }}
colors={['#30FFBA', '#7C68FF']}
curve="natural"
enableArea={true}
axisBottom={axisBottomByType(inviteSummary.type)}

yScale={{
type: 'linear',
min: 0,
max: 100,
stacked: false,
reverse: false
}}
lineWidth={5}
pointSize={0}
pointColor={{ theme: 'background' }}
pointBorderWidth={2}
pointBorderColor={{ from: 'serieColor' }}
pointLabelYOffset={-12}
useMesh={true}
layers={[
    // IDK why I had to add the as keyword (typescript)
    "grid",
    "axes",
    "areas",
    "lines",
    "points",
    "slices",
    "mesh",
    "legends",
    // Add markers as the last one so it's on top
    "markers"
  ]}
markers={[
    {
      axis: 'y',
      legend: 'High Impact',
      legendOrientation: 'horizontal',
      legendPosition: 'top-left',
      lineStyle: {
        stroke: '#01A878',
        strokeWidth: 2
      },
      value: 75
    },
    {
      axis: 'y',
      legend: 'Low Impact',
      legendOrientation: 'horizontal',
      legendPosition: 'bottom-left',
      translateX: 100,
      lineStyle: {
        stroke: '#ff0000',
        strokeWidth: 2
      },
      value: 35
    }
  ]}
legends={[
{
anchor: 'top-left',

direction: 'row',
justify: false,
translateX: 0,
translateY: -30,
itemsSpacing: 0,
itemDirection: 'left-to-right',
itemWidth: 80,
itemHeight: 20,
itemOpacity: 0.75,
symbolSize: 12,
symbolShape: 'circle',
symbolBorderColor: 'rgba(0, 0, 0, .5)',
effects: [
{
    on: 'hover',
    style: {
        itemBackground: 'rgba(0, 0, 0, .03)',
        itemOpacity: 1
    }
}
]
}
]}
/>

</div>


}

    </Grid>

    </Grid>




{ inviteSummary.type === "live" && data && !isLoadingData && data.length < 60 && 
        <Grid container spacing={5} alignItems="stretch" style={{marginTop: '60px'}} ref={timelineRef}>
              
              <Grid item xs={6} >
                <Typography variant={`h4`} color="textPrimary" gutterBottom={false} >
                        TIMELINE
                </Typography>

                </Grid>

                <Grid item xs={6} alignItems="flex-end"  >
        <Grid container  alignContent="flex-end" alignItems="flex-end" justifyContent="flex-end">
          <Grid item={true} xs={12}  className={classes.actionBar} style={{display: 'grid', justifyItems: "right"}}>

          {!isLoadingData && !!data && inviteSummary && 

            <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
      
            

                <Button variant="contained" color="secondary" size="medium" className={classes.button} disabled={isPaid ? false : true}>
                    <ImageIcon onClick={downloadTimelineSection}/> 
                </Button>
      
                <CustomCvsButton variant="contained" color="secondary" data={formatDataForTimeline(data)} filename={`SIX_event_timeline_export_${aggWindow}_${moment().format('YYYYMMDD_HH-mm-ss')}.csv`} delimiter="," disabled={isPaid ? false : true} size="medium" className={classes.button}>
                    <CloudDownloadIcon />
                </CustomCvsButton>
              
            </ButtonGroup>

          }

          </Grid>
        </Grid>
    </Grid>



                <Grid item xs={12} className={isPaid === true ? classes.noBlur : classes.doBlur}>

                {data && data.length > 60 && 
                    <Grid container={true} className={classes.buffer}>
                    <Grid item={true} xs={12} md={12} className={classes.gridItemCenter} >    
                        <Typography variant="h6">Data set is too large for display in this format.</Typography>
                    </Grid>
                    </Grid>
                }


                    {data && data.length >= 1 && data.length <= 60 && 

                        <Timeline align="alternate">

                    { formatDataForTimeline(data).map((item, index) => 
                    <TimelineItem key={item.id}>
                        <TimelineOppositeContent>
                        <Typography variant="h6" color="textSecondary" style={{fontWeight: 'bold', marginTop: '24px'}}>
                            { moment(item.start).format("h:mm a") }
                        </Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                        <TimelineDot style={{backgroundColor: "#ffffff00", boxShadow: "none"}}>
                        <TimelineResultIcon maxheight={'70px'} maxwidth={'70px'} fontsize={'35px'} scoretopbuffer={'15px'} experiencedata={[]} immersionScore={item.value} safetyScore={item.safety} />

                        </TimelineDot>
                        <TimelineConnector style={{backgroundColor: "#d7d7d7", minHeight: '30px'}} />
                        </TimelineSeparator>
                        <TimelineContent>
                        <Paper elevation={3} className={classes.paper}>
                            <Typography variant="h6" component="h1">
                            Value: {item.value}
                            </Typography>
                            <Typography>Safety: {item.safety}</Typography>
                        </Paper>
                        </TimelineContent>
                    </TimelineItem>

                    )}
                            


                    </Timeline>

                            }

                    </Grid>

        </Grid>
  }

        </>
    }



        </>
    )



  }