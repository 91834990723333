import React, { useState, useEffect, useRef, useCallback } from "react";
import Grid from "@material-ui/core/Grid";
import { Timestamp } from "firebase/firestore";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useAcceptedAccountList, useShareRequestsAcceptedList } from "../util/db";
import { InviteReportDemographicsTile } from "./InviteReportDemographicsTile";
import { InviteReportDemographicsTileRecorded } from "./InviteReportDemographicsTileRecorded";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import ImageIcon from '@material-ui/icons/Image';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CustomCvsButton from "./CustomCvsButton";
import html2canvas from 'html2canvas';


const moment = require('moment');



const useStyles = makeStyles((theme) => ({
    headline: {
      //fontWeight: "800",
      //color: "#ffffff",
    },
    doBlur: {
      filter: "blur(15px)",
    },
    noBlur: {
      //filter: "blur(15px)",
    },
    hrMargin: {
      margin: "30px",
    },
    button: {
      background: '#000000',
    },
    paper: {
        padding: '6px 16px',
      },
      secondaryTail: {
        backgroundColor: theme.palette.secondary.main,
      },
  }));

export const InviteReportDemoSection = (props) => {
    const classes = useStyles();
    const demoRef = useRef();

    const { startRange, endRange, acceptedAccounts, inviteSummary, isPaid, ...otherProps } = props;

    const [accounts, setAccounts] = useState([]);    
    const [genderMap, setGenderMap] = useState(undefined);
    const [ageMap, setAgeMap] = useState(undefined);
    const [phoneMap, setPhoneMap] = useState(undefined);
    const [sensorMap, setSensorMap] = useState(undefined);
    const accountIds = !!acceptedAccounts ? acceptedAccounts.map(item => { return item.account_id; }) : [];



    const { data: acceptedAccountDetails, status: resultsStatus, error: resultsError, } = useAcceptedAccountList(accountIds);


    
    useEffect(() => {
        if (acceptedAccountDetails) {
          //console.log("acceptedAccounts!!!!");

          const gender = new Map();
          const age = new Map();
          const phone = new Map();
          const sensor = new Map();


          gender.set("Male", acceptedAccountDetails.map(item => { if (item.gender === "Male") { return item.id; }}));
          gender.set("Female", acceptedAccountDetails.map(item => { if (item.gender === "Female") { return item.id; }}));
          gender.set("Other", acceptedAccountDetails.map(item => { if (item.gender === "Other") { return item.id; }}));
          gender.set("N/A", acceptedAccountDetails.map(item => { if (item.gender === "N/A") { return item.id; }}));

          age.set("0-15", acceptedAccountDetails.map(item => { if (item.age_group === "0-15") { return item.id; }}));
          age.set("16-25", acceptedAccountDetails.map(item => { if (item.age_group === "16-25") { return item.id; }}));
          age.set("26-35", acceptedAccountDetails.map(item => { if (item.age_group === "26-35") { return item.id; }}));
          age.set("36-45", acceptedAccountDetails.map(item => { if (item.age_group === "36-45") { return item.id; }}));
          age.set("46-55", acceptedAccountDetails.map(item => { if (item.age_group === "46-55") { return item.id; }}));
          age.set("56+", acceptedAccountDetails.map(item => { if (item.age_group === "56+") { return item.id; }}));
          age.set("N/A", acceptedAccountDetails.map(item => { if (item.age_group === "N/A") { return item.id; }}));

          phone.set("iOS", acceptedAccountDetails.map(item => { if (item.phone_type === "iOS") { return item.id; }}));
          phone.set("Android", acceptedAccountDetails.map(item => { if (item.phone_type === "Android") { return item.id; }}));

            const senTypes = Array.from(new Set(acceptedAccountDetails.map((item) => item.active_device)));

            //console.log(senTypes);
            for (let senItem of senTypes) {
                var title = "Unknown";
                if (senItem !== null && senItem !== undefined) {
                    title = senItem;
                }
                sensor.set(title, acceptedAccountDetails.map(item => { if (item.active_device === senItem) { return item.id; }}));
            }

          for (let [key, value] of gender) {
            var data = value.filter(function( element ) {
                return element !== undefined;
             });    
             gender.set(key, data);
          }

          for (let [key, value] of age) {
            var data = value.filter(function( element ) {
                return element !== undefined;
             });    
             age.set(key, data);
          }

          for (let [key, value] of phone) {
            var data = value.filter(function( element ) {
                return element !== undefined;
             });    
             phone.set(key, data);
          }

          for (let [key, value] of sensor) {
            var data = value.filter(function( element ) {
                return element !== undefined;
             });    
             sensor.set(key, data);
          }

          setGenderMap(gender);
          setAgeMap(age);
          setPhoneMap(phone);
          setSensorMap(sensor);

         // console.log(gender);
         // console.log(age);
         // console.log(phone);
         // console.log(sensor);

        }

      }, [acceptedAccountDetails]);


      function getTiles(mymap) {
        
        if (mymap) {

            const comps = [];
            if (inviteSummary.type === "live") {
              mymap.forEach((value, key) => comps.push(<InviteReportDemographicsTile key={key} title={key} startRange={startRange} endRange={endRange} accountIds={value} isPaid={isPaid} />));
            } else {
              mymap.forEach((value, key) => comps.push(<InviteReportDemographicsTileRecorded key={key} title={key} inviteId={inviteSummary.id} accountIds={value} isPaid={isPaid} />));
            }
             return comps;
        }
      };


      const handleDownloadImage = async (element, name) => {
        //const element: any = overviewRef.current;
        const canvas = await html2canvas(element);
      
        const data = canvas.toDataURL('image/jpg');
        const link = document.createElement('a');
      
        if (typeof link.download === 'string') {
          link.href = data;
          link.download = name + '_image.jpg';
      
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          window.open(data);
        }
      };

      const downloadTimelineSection = useCallback(() => {
        handleDownloadImage(demoRef.current, 'SIX_demographics');
      }, [inviteSummary]);




    return (
        <>
        
        <Grid container spacing={5} alignItems="stretch" style={{marginTop: '50px'}} ref={demoRef} className={isPaid === true ? classes.noBlur : classes.doBlur}>

        <Grid item xs={8} md={6} >
            <Typography variant={`h4`} color="textPrimary" className={classes.headline} gutterBottom={false} >
                DEMOGRAPHICS
            </Typography>
        </Grid>

        <Grid item xs={4} md={6} style={{display: 'grid', justifyItems: "right"}} >
              <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">

                  <Button variant="contained" color="secondary" size="medium" className={classes.button} disabled={isPaid ? false : true}>
                      <ImageIcon onClick={downloadTimelineSection} /> 
                  </Button>

                 {/* <CustomCvsButton variant="contained" color="secondary" data={genderMap} filename={`SIX_demo_export_${moment().format('YYYYMMDD_HH-mm-ss')}.csv`} delimiter="," disabled={isPaid ? false : true} size="medium" className={classes.button}>
                      <CloudDownloadIcon />
                  </CustomCvsButton>
                 */}

                </ButtonGroup>
        </Grid>



        <Grid item xs={12} md={12} >
            <Typography variant={`h5`} color="textPrimary" className={classes.headline} gutterBottom={false} >
                GENDER
            </Typography>
        </Grid>

            { acceptedAccountDetails && genderMap && getTiles(genderMap) }

        <Grid item xs={12} md={12} >
            <Typography variant={`h5`} color="textPrimary" className={classes.headline} gutterBottom={false} >
                AGE GROUP
            </Typography>
        </Grid>

            { acceptedAccountDetails && ageMap && getTiles(ageMap) }

        <Grid item xs={12} md={12} >
            <Typography variant={`h5`} color="textPrimary" className={classes.headline} gutterBottom={false} >
                PHONE TYPE
            </Typography>
        </Grid>

            { acceptedAccountDetails && phoneMap && getTiles(phoneMap) }    

        <Grid item xs={12} md={12} >
            <Typography variant={`h5`} color="textPrimary" className={classes.headline} gutterBottom={false} >
                SENSOR TYPE
            </Typography>
        </Grid>

            { acceptedAccountDetails && sensorMap && getTiles(sensorMap) }    




        </Grid>

        </>
    )

};