import React, {useState, useEffect, useReducer, useRef, useCallback} from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useRouter } from "./../util/router";
import { useAuth } from "./../util/auth";
import { apiRequestWithKey } from "./../util/util"
import { useShareRequestsAcceptedList } from "../util/db";
//import { InfluxDB } from "@influxdata/influxdb-client";
import { useMediaQuery } from "@material-ui/core";
import { ResponsiveCirclePacking } from '@nivo/circle-packing'

const moment = require('moment');

const useStyles = makeStyles((theme) => ({
    cardContent: {
      padding: theme.spacing(3),
    },
    forceH: {
      height: "800px",
    },
    forceScatterH: {
      minHeight: "800px",
    },
    bodyBackground: {
      backgroundImage: `url(./images/bk_high.jpg)`,
      backgroundSize: "cover",
      minHeight: "100vh",
      animation: "fadeInBodyAnimation ease 3s",
      animationIterationCount: "1",
      animationFillMode: "forwards",
    },
    root: {
      //paddingTop: "10px",
      // Add bottom margin if element below
      "&:not(:last-child)": {
        marginBottom: "2rem",
      },
    },
    statbox: {
      padding: '10px',
    },
    white: {
      color: '#ffffff',
    },
  
    white60: {
      color: 'rgba(255, 255, 255, 0.6)',
      
    },
    scoreicon: {
      //paddingTop: '5px',
      height: '100%',
      width: '100%',
    },
    imgheader: {
      // Add bottom margin if element below
      height: '400px'
    },
    actionBar: {
      //boxShadow: `1px 1px 0 0 ${emphasize(theme.palette.background.paper, 0.08)}`,
      textAlign: "right",
    },
    subtitle: {
      maxWidth: 700,
      display: "inline-block",
    },
    gridItem: {
      //boxShadow: `1px 1px 0 0 ${emphasize(theme.palette.background.paper, 0.08)}`,
      textAlign: "left",
    },
    gridItemCenter: {
        textAlign: "center",
      },
    actionBar: {
      //boxShadow: `1px 1px 0 0 ${emphasize(theme.palette.background.paper, 0.08)}`,
      textAlign: "right",
    },
    buffer: {
      marginBottom: "30px",
    },
    doBlur: {
      filter: "blur('25px')",
    },
    eventImage: {
      maxWidth: "100%",
      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
      borderRadius: "10px",
    },
    headline: {
      //fontWeight: "800",
      marginBottom: '0px',
      color: "#ffffff",
    },
    headlineBlack: {
        //fontWeight: "800",
        marginBottom: '0px',
        color: "#000000",
      },
    of100: {
      fontSize: ".8em",
      color: 'rgba(255, 255, 255, 0.6)',
    },
    peopleCard: {
      background: '#062A53',
      borderRadius: '10px',
    },
    button: {
        background: '#000000',
      },
      p: {
        marginBlockStart: '0',
        marginBlockEnd: '0'
      },
      renderMarks: {
        display: 'inline'
      }
  }));
  
/***
 * 
 * https://docs.sheetjs.com/docs/demos/frontend/react
 */

//TODO:  influx query to get data and calc key moments

const circleData = {
  "name": "BASE",
  "color": "hsl(0, 70.20%, 90.00%)",
  "children": [
    {
      "name": "2:50pm",
      "color": "hsl(215, 70%, 50%)",
      "imm": 90,
      "start": 12,
      "end": 15,
      "sd": "startdate",
          "ed": "endDate",
      "children": [
        {
          "name": "2:50pm",
          "color": "hsl(249, 70.20%, 50.00%)",
          "scale": 1230,
          "imm": 90,
          "start": 12,
          "sd": "2:50pm",
          "ed": "3:05pm",
          "end": 15
        }
      ]
    },
    {
      "name": "10:35am",
      "color": "hsl(156, 70.20%, 50.00%)",
      "imm": 50,
      "start": 12,
      "end": 15,
      "sd": "startdate",
          "ed": "endDate",
      "children": [
        {
          "name": "10:35am",
          "color": "hsl(76, 70.20%, 50.00%)",
          "imm": 50,
          "scale": 2880,
          "start": 12,
          "sd": "10:35am",
          "ed": "10:55am",
          "end": 15
        }
      ]
    },
    {
      "name": "12:37pm",
      "color": "hsl(335, 70.20%, 50.00%)",
      "imm": 30,
      "start": 12,
      "end": 15,
      "sd": "startdate",
      "ed": "endDate",
      "children": [
        {
          "name": "12:37pm",
          "color": "hsl(291, 70.20%, 50.00%)",
          "imm": 30,
          "scale": 1440,
          "sd": "12:37pm",
          "ed": "1:15pm",
          "start": 12,
          "end": 15
        }
      ]
    },
    {
      "name": "4:35pm",
      "color": "hsl(335, 70.20%, 50.00%)",
      "imm": 27,
      "start": 12,
      "end": 15,
      "sd": "startdate",
          "ed": "endDate",
      "children": [
        {
          "name": "4:35pm",
          "color": "hsl(291, 70.20%, 50.00%)",
          "imm": 27,
          "scale": 1140,
          "sd": "4:35pm",
          "ed": "4:55pm",
          "start": 12,
          "end": 15
        }
      ]
    },
    {
      "name": "2:07pm",
      "color": "hsl(335, 70.20%, 50.00%)",
      "imm": 22,
      "start": 12,
      "end": 15,
      "sd": "startdate",
          "ed": "endDate",
      "children": [
        {
          "name": "2:07pm",
          "color": "hsl(291, 70.20%, 50.00%)",
          "imm": 22,
          "scale": 1440,
          "sd": "2:07pm",
          "ed": "2:25pm",
          "start": 12,
          "end": 15
        }
      ]
    }
  ]
};

  export const InviteReportKeyMomentSection = (props) => {


    const classes = useStyles();

    const { startRange, endRange, inviteSummary, isPaid, ...otherProps } = props;

    const auth = useAuth();
    const router = useRouter();
    const scrollRef = useRef();
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("xs"));
    const [isLoadingData, setIsLoadingData] = useState(true);
    const [data, setData] = useState([]);
    const [aggWindow, setAggWindow] = useState("10m");
    const [inviteDataError, setInviteDataError] = useState("");

    const [zoomedId, setZoomedId] = useState(null);
    const [circleListData, setCircleListData] = useState(null);


    const [kmData, setKmData] = useState([]);

    

    useEffect(() => {
          if (!!inviteSummary) {
            async function fetchData() {
                setIsLoadingData(true);
                const tempAgg = inviteSummary.duration_seconds > 3600 ? "5m" : "5m";  //if event is longer than 1 hour, set to 10 min, else 5min
                setAggWindow(tempAgg);

                let url = `query-event?id=${inviteSummary.id}&agg=${tempAgg}`;
                //console.log(url);
                try {
                    if (inviteSummary.audience.completed > 0) {
                        const temp = await apiRequestWithKey(url);  //get inviteSummary from API
                        //console.log(temp);
                        setData(temp);

                        //const newChartData = formatDataForGraph(inviteSummary.type, temp);
                        //setGraphData(newChartData);
                        let listItems = [];
                        //var counter = -1

                        temp.forEach(row => {
                          //counter = counter + 1

                          if (row.value >= 75) {
                            //this is a km
                            let endMoment =  moment.unix(row.ts);
                            let startMoment = moment.unix(row.ts).subtract(tempAgg === "10m" ? 10 : 5, "minutes");

                           // startMoment.subtract(tempAgg === "10m" ? 10 : 5, "minutes");
                            let scaler = tempAgg === "10m" ? 10 : 5;
                            let scale = Math.round(scaler * row.value);


                            var circleObject = {
                              "name": startMoment.format("h:mma"),
                              "color": "hsl(291, 70.20%, 50.00%)",
                              "imm": row.value,
                              "scale": scale,
                              "sd": startMoment.format("h:mma"),
                              "ed": endMoment.format("h:mma"),
                              "start_time": startMoment.unix(),
                              "end_time": endMoment.unix()
                            };

                            //listItems.push(circleObject);


                            if (listItems.length >= 1) {
                              if (listItems[listItems.length-1].end_time === circleObject.start_time) {
                                  //join this together
                                  var co = listItems[listItems.length-1]

                                  co.ed = circleObject.ed;
                                  co.end_time = circleObject.end_time;
                                  co.imm = Math.round((co.imm + circleObject.imm) / 2);

                                  let diff = Math.round((co.end_time - co.start_time)/60);
                                  co.scale = Math.round(diff * co.imm);

                                  listItems[listItems.length-1] = co

                              } else {
                                listItems.push(circleObject);
                              }
                            } else {
                              listItems.push(circleObject);
                            }



                          }


                        });
                       
                        //console.log(listItems);

                        if (listItems.length >= 1) {
                          //i have items...

                          let tempcircleListData = {"name": "BASE", "color": "hsl(0, 70.20%, 90.00%)", "children": []};

                          listItems.forEach(item => {
                            let newItem = JSON.parse(JSON.stringify(item));
                            newItem["children"] = Array(item);
                            tempcircleListData.children.push(newItem);

                          });


                          setCircleListData(tempcircleListData)

                          //console.log(tempcircleListData);

                        }


                    }
                    setIsLoadingData(false);
                } catch (error) {
                    if (error != undefined) {
                        console.log(error);
                        setInviteDataError(error.message);
                    }
                    setIsLoadingData(false);
                }
              }
              fetchData();
          }

    }, []);
   

    const getValueScoreTooltip = (id) => {
      var valueScore = 0;
      var kids = circleListData.children;

      for (const item of kids) {
        if (item.name === id) {
          valueScore = item.imm;
          break;
        }
      }
      return valueScore;
    }



    return (
      <>
{ !!inviteSummary && isPaid && !!circleListData && 

<>

            <Typography variant="h6" className={classes.white60} style={{position: "absolute", top: '0px', paddingLeft: '12px'}}>KEY MOMENTS</Typography>
            <div style={{height: "100%", width: "100%", position: "absolute", top: '0px' }}>
            

            <ResponsiveCirclePacking
        data={circleListData}
        margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
        id="name"
        value="scale"
        colors={ ['#30FFBA', '#B7D9FF', '#1CEBFF', '#AFB2FF', '#F6D4FB','#30FFBA', '#B7D9FF', '#1CEBFF', '#AFB2FF', '#F6D4FB'] }
        colorBy="id"
        label={e=>e.id === zoomedId ? e.data.sd+" → "+e.data.ed : e.id} //+": "+e.imm}
        valueFormat=" >-d"
        leavesOnly
        zoomedId={zoomedId} motionConfig="slow" onClick={node => {
          setZoomedId(zoomedId === node.id ? null : node.id);
        }}
        tooltip={({
          id,
          value,
          color
        }) => 
          
          <div style={{background: 'white', color: 'inherit', fontSize: 'inherit', borderRadius: '2px', boxShadow: `${color} 0px 1px 2px`, padding: '5px 9px'}}>
            <div style={{whiteSpace: 'pre', display: 'flex', alignItems: 'center'}} >
            <span style={{ backgroundColor: `${color}`, display: 'block', width: '12px', height: '12px', marginRight: '7px'}} ></span>
              <span>{id}: <strong>Value = {getValueScoreTooltip(id)}</strong></span>
            </div>
          </div>
        
        
        
        
                      
                    
                    } 
                      
                      
        /*theme={{ , marginRight: '7'

        style="background: white; color: inherit; font-size: inherit; border-radius: 2px; box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 2px; padding: 5px 9px;"

 style="white-space: pre; display: flex; align-items: center;"



          tooltip: {
            container: {
              background: '#333333'
            }
          }
        }}
          
        <strong style={{color, background: '#ffffff', padding: '5px', border: '1px solid red', borderColor: color
          
        }}>
                          KEY MOMENT <br/>
                          {id}<br/>
                          Value: {value}
                      </strong>
        
        */
//style="display: block; width: 12px; height: 12px; background: rgb(183, 217, 255); margin-right: 7px;"
        //motionConfig="gentle"
        //isInteractive={true}
        //animate={true}
        /*childColor={{
            from: 'color',
            modifiers: [
                [
                    'brighter',
                    0.4
                ]
            ]
        }}*/
        padding={1}
        enableLabels={true}
        labelsFilter={n=>2===n.node.depth}
        labelsSkipRadius={10}
        labelTextColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    2
                ]
            ]
        }}
        borderWidth={0}
        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    0.5
                ]
            ]
        }}
    />

</div>

</>
}

</>
            
        )






  }

 