import React, { useState, useEffect, useRef, useCallback } from "react";
import { InfluxDB } from "@influxdata/influxdb-client";
import Grid from "@material-ui/core/Grid";
import { Timestamp } from "firebase/firestore";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useShareResultScoresFromArray } from "../util/db";

//const moment = require('moment');


//const token = process.env.REACT_APP_INFLUX_TOKEN;
//const org = process.env.REACT_APP_INFLUX_ORG;
//const bucket = process.env.REACT_APP_INFLUX_BUCKET;
//const url = process.env.REACT_APP_INFLUX_URL;

const useStyles = makeStyles((theme) => ({
    headline: {
      //fontWeight: "800",
      //color: "#ffffff",
    },
    doBlur: {
      filter: "blur(15px)",
    },
    noBlur: {
      //filter: "blur(15px)",
    },
    hrMargin: {
      margin: "30px",
    },
    tileBack: {
        background: '#1CEBFF',//#CDCAFF
    },
    button: {
      background: '#000000',
    },
    scoreicon: {
        //paddingTop: '5px',
        height: '100%',
        width: '100%',
      },
      of100: {
        fontSize: ".8em",
        fontWeight: "bold",
        color: 'rgba(255, 255, 255, 0.6)',
      },
    paper: {
        padding: '6px 16px',
      },
      secondaryTail: {
        backgroundColor: theme.palette.secondary.main,
      },
  }));


  export const InviteReportDemographicsTileRecorded = (props) => {
    const classes = useStyles();

    const { keyName, title, inviteId, accountIds, isPaid, ...otherProps } = props;

    const [loading, setLoading] = useState(true);    

    //const [queryResults, setQueryResults] = useState(undefined);

    const [immersion, setImmersion] = useState(0);    
    const [safety, setSafety] = useState(0);  
    //const [hasData, setHasData] = useState(false);
    //var aggWindow = "1m";



 const { data: scoreAccountObjects, status: resultsStatus2, error: resultsError2, } = useShareResultScoresFromArray(inviteId, accountIds);

 //console.log(`scoreAccountObjects -> ${JSON.stringify(scoreAccountObjects)}`);
 //console.log(`resultsStatus2 -> ${resultsStatus2}`);
 //console.log(`resultsError2 -> ${resultsError2}`);


useEffect(() => {

    if (!!scoreAccountObjects) {
        //console.log(`scoreAccountObjects RETURNED -> ${scoreAccountObjects.length}`);
        var immersionScores = [];
        var safetyScores = [];
        scoreAccountObjects.forEach(participant => {
          immersionScores.push(participant.immersion);
          safetyScores.push(participant.safety);
        })


        setImmersion(immersionScores.length >= 1 ? Math.round(averageArray(immersionScores)) : 0);
        setSafety(safetyScores.length >= 1 ? Math.round(averageArray(safetyScores)) : 0);

        setLoading(false);
        
    }
    
    //
  },[scoreAccountObjects]);


/*
    useEffect(() => {
        if (accountIds.length >= 1) {
          //console.log("shareAccepts!!!!");
          loadData(accountIds, startRange, endRange);
        } else {
            setLoading(false);
        }

      }, []);
*/
      /*const arrayToQueryString = (arr) => {
        var r = "[\"";
        let authorString = arr.join("\",\"");
        //console.log(authorString);
        const x = r + authorString + "\"]";
        //console.log(x);
        return x;
      }*/

      const averageArray = (nums) => {
        if (nums.length > 0) {
          return nums.reduce((a, b) => (a + b)) / nums.length;
        } else {
          return 0
        }
    }

    /*
    const loadData = (accountIds, start, end) => {
        //console.log("loadMinuteData");

        let regArray = accountIds.join("|");

//|> filter(fn: (r) => contains(value: r["pid"], set: ${arrayToQueryString(accountIds)}))

        let localQuery = `data = from(bucket: "${bucket}")
            |> range(start: ${start}, stop: ${end})
            |> filter(fn: (r) => r["_measurement"] == "score")
            |> filter(fn: (r) => r["_field"] == "e" or r["_field"] == "s")
            |> filter(fn: (r) => r["pid"] =~ /^(${regArray})$/)
            |> aggregateWindow(every: ${aggWindow}, fn: mean, createEmpty: true)
            |> pivot(rowKey: ["_time"], columnKey: ["_field"], valueColumn: "_value" )
            |> group(columns: ["_time"], mode: "by")
            
            mean_e = data
	            |> mean(column: "e")

            mean_s = data
              |> mean(column: "s")

            join(tables: {t1: mean_e, t2: mean_s}, on: ["_time"])`;

            //console.log("localQuery = " + localQuery);

        let res = [];
        const influxQuery = async () => {
          //create InfluxDB client
          const queryApi = await new InfluxDB({ url, token }).getQueryApi(org);
          //console.log(queryApi);
    
          //make query
          await queryApi.queryRows(localQuery, {
            next(row, tableMeta) {
             //  console.log("a");
              const o = tableMeta.toObject(row);
             //push rows from query into an array object
              res.push(o);
            },
            complete() {
              
    
              let tmpDownloadDataset = [] 
    
              let finalData = []
              let engagement = []
              let safety = []

              let engagementPoints = []
              let safetyPoints = []


              //console.log(res);
              if (res.length > 0) {
                setHasData(true);
                
              } else {
                setHasData(false);
                setLoading(false);
              }

              for(let i = 0; i < res.length; i++) {
                
                
                const e = res[i]['e'] !== undefined ? res[i]['e'] : 0.0;
                const s = res[i]['s'] !== undefined ? res[i]['s'] : 0.0;

                engagementPoints.push(Math.round(e));
                safetyPoints.push(Math.round(s));

                engagement.push({ "x": res[i]['_time'], "y" : Math.round(e) });
                safety.push({ "x" : res[i]['_time'], "y" : Math.round(s) });
    
                tmpDownloadDataset.push({"date": res[i]['_time'], "value": Math.round(e), "safety": Math.round(s)});
    
                
              }
    


              finalData.push({"id": "Value Score", "data" : engagement});
              finalData.push({"id": "Safety", "data" : safety});
    
             // console.log(finalData);
    
        //      setData(finalData);
        //      setImmersionDataPoints(engagementPoints);
        //      setSafetyDataPoints(safetyPoints);

         

              let is = Math.round(averageArray(engagementPoints));
              let ss = Math.round(averageArray(safetyPoints));
              setImmersion(is);
              setSafety(ss);

              setLoading(false);


             // console.log(engagementPoints);
             // console.log(safetyPoints);

        //      setDownloadData(tmpDownloadDataset);
        //      downloadCallback(tmpDownloadDataset);
        //      scoreCallback(is, ss, expShape);



            },
            error(error) {
              console.log("query failed- ", error);
            }
          });
         
        };
    
        influxQuery();
    
      }
*/

    return (
        <>
        
        <Grid key={title} item xs={6} sm={3} className={isPaid === true ? classes.noBlur : classes.doBlur}>
        <Card className={classes.tileBack}>
            <CardContent>
            <Typography variant={`h5`} color="textPrimary" className={classes.headline} gutterBottom={false} >
                {title}
            </Typography>

            { loading && 
                <CircularProgress />
            }

{ loading === false && 
<Grid container={true} >

<Grid item={true} xs={2} >
<img src="/images/icons/brain.svg" className={classes.scoreicon}/>
</Grid>
<Grid item={true} xs={10} >
<Typography variant="h4"  className={classes.headline} gutterBottom={false}>
{immersion}<Typography variant="caption"  className={classes.of100} gutterBottom={false}>/100</Typography>
</Typography>
</Grid>

<Grid item={true} xs={2} >
<img src="/images/icons/safety.svg" className={classes.scoreicon}/>
</Grid>
<Grid item={true} xs={10} >
<Typography variant="h4"  className={classes.headline} gutterBottom={false}>
{safety}<Typography variant="caption"  className={classes.of100} gutterBottom={false}>/100</Typography>
</Typography>
</Grid>


</Grid>

  }


            </CardContent>
        </Card>


                    
        </Grid>

        </>
    )


  };