import {
  useQuery,
  hashQueryKey,
  QueryClient,
  QueryClientProvider as QueryClientProviderBase,
} from "react-query";
import {
  getFirestore,
  onSnapshot,
  doc,
  collection,
  collectionGroup,
  query,
  where,
  orderBy,
  getDoc,
  setDoc,
  updateDoc,
  addDoc,
  deleteDoc,
  serverTimestamp,
} from "firebase/firestore";
import { firebaseApp } from "./firebase";

// Initialize Firestore
const db = getFirestore(firebaseApp);

// React Query client
const client = new QueryClient();

/**** USERS ****/

// Subscribe to user data
// Note: This is called automatically in `auth.js` and data is merged into `auth.user`
export function useUser(uid) {
  // Manage data fetching with React Query: https://react-query.tanstack.com/overview
  return useQuery(
    // Unique query key: https://react-query.tanstack.com/guides/query-keys
    ["user", { uid }],
    // Query function that subscribes to data and auto-updates the query cache
    createQuery(() => doc(db, "users", uid)),
    // Only call query function if we have a `uid`
    { enabled: !!uid }
  );
}

// Fetch user data once (non-hook)
// Useful if you need to fetch data from outside of a component
export function getUser(uid) {
  return getDoc(doc(db, "users", uid)).then(format);
}

export function getAccount(uid) {
  return getDoc(doc(db, "accounts", uid)).then(format);
}

// Create a new user
export function createUser(uid, data) {
  return setDoc(doc(db, "users", uid), data, { merge: true });
}

// Update an existing user
export function updateUser(uid, data) {
  return updateDoc(doc(db, "users", uid), data);
}


/**** TEAM ****/
/* Example query functions (modify to your needs) */

// Subscribe to item data
export function useTeam(id) {
  //console.log("useTeam = " + id);
  return useQuery(
    ["team", { id }],
    createQuery(() => doc(db, "team", id)),
    { enabled: !!id }
  );
}

// Fetch item data once
export function useTeamOnce(id) {
  return useQuery(
    ["team", { id }],
    // When fetching once there is no need to use `createQuery` to setup a subscription
    // Just fetch normally using `getDoc` so that we return a promise
    () => getDoc(doc(db, "team", id)).then(format),
    { enabled: !!id }
  );
}

// Subscribe to all items by owner
export function useTeamsByOwner(owner) {
  return useQuery(
    ["teams", { owner }],
    createQuery(() =>
      query(
        collection(db, "team"),
        where("owner", "==", owner),
        orderBy("createdAt", "desc")
      )
    ),
    { enabled: !!owner }
  );
}

// Subscribe to all teams by api_key
export function useTeamsByApiKey(key) {
  return useQuery(
    ["teams", { key }],
    createQuery(() =>
      query(
        collection(db, "team"),
        where("api_key", "==", key),
        orderBy("createdAt", "desc")
      )
    ),
    { enabled: !!key }
  );
}


// Create a new item
export function createTeam(data) {
  return addDoc(collection(db, "team"), {
    ...data,
    createdAt: serverTimestamp(),
  });
}

// Update an item
export function updateTeam(id, data) {
  return updateDoc(doc(db, "team", id), data);
}

// Delete an item
export function deleteTeam(id) {
  return deleteDoc(doc(db, "team", id));
}



/**** INTERVENTIONS ****/
/* Example query functions (modify to your needs) */

// Subscribe to item data
export function useIntervention(id) {
  return useQuery(
    ["intervention", { id }],
    createQuery(() => doc(db, "interventions", id)),
    { enabled: !!id }
  );
}

// Fetch item data once
export function useInterventionOnce(id) {
  return useQuery(
    ["intervention", { id }],
    // When fetching once there is no need to use `createQuery` to setup a subscription
    // Just fetch normally using `getDoc` so that we return a promise
    () => getDoc(doc(db, "interventions", id)).then(format),
    { enabled: !!id }
  );
}

// Subscribe to all items by owner
export function useInterventionsByOwner(owner) {
  return useQuery(
    ["interventions", { owner }],
    createQuery(() =>
      query(
        collection(db, "interventions"),
        where("owner", "==", owner),
        orderBy("createdAt", "desc")
      )
    ),
    { enabled: !!owner }
  );
}

// Subscribe to all items by owner
export function useInterventionsByTeam(teamId) {
  //console.log(`teamId is ${teamId}`);
  return useQuery(
    ["interventions", { teamId }],
    createQuery(() =>
      query(
        collection(db, "interventions"),
        where("team_id", "==", teamId),
        where("active", "==", true),
        orderBy("created_at", "desc")
      )
    ),
    { enabled: !!teamId }
  );
}

// Create a new item
export function createIntervention(data) {
  return addDoc(collection(db, "interventions"), {
    ...data,
    active: true,
    created_at: serverTimestamp(),
  });
}

// Update an item
export function updateIntervention(id, data) {
  return updateDoc(doc(db, "interventions", id), { ...data, updated_at: serverTimestamp() });
}

// Update an item
export function disableIntervention(id) {
  return updateDoc(doc(db, "interventions", id), {active: false});
}

// Delete an item
export function deleteIntervention(id) {
  return deleteDoc(doc(db, "interventions", id));
}


/**** SHARE_REQUESTS ****/
/* Example query functions (modify to your needs) */

// Subscribe to item data
export function useShareRequest(id) {
  return useQuery(
    ["share_request", { id }],
    createQuery(() => doc(db, "share_requests", id)),
    { enabled: !!id }
  );
}

// Fetch item data once
export function useShareRequestOnce(id) {
  return useQuery(
    ["share_request", { id }],
    // When fetching once there is no need to use `createQuery` to setup a subscription
    // Just fetch normally using `getDoc` so that we return a promise
    () => getDoc(doc(db, "share_request", id)).then(format),
    { enabled: !!id }
  );
}

// Subscribe to all items by owner
export function useShareRequestsByOwner(owner) {
  return useQuery(
    ["sharerequests", { owner }],
    createQuery(() =>
      query(
        collection(db, "share_request"),
        where("owner", "==", owner),
        orderBy("createdAt", "desc")
      )
    ),
    { enabled: !!owner }
  );
}

// Subscribe to all items by owner
export function useShareRequestsByTeam(teamId) {
 // console.log(`teamId is ${teamId}`);
  return useQuery(
    ["sharerequests", { teamId }],
    createQuery(() =>
      query(
        collection(db, "share_request"),
        where("owner", "==", teamId),
        where("active", "==", true),
        orderBy("created_at", "desc")
      )
    ),
    { enabled: !!teamId }
  );
}

// Subscribe to all items by owner
export function useShareRequestsByTeamAndType(teamId, type) {
  //console.log(`teamId is ${teamId}`);
  return useQuery(
    ["sharerequests", { teamId }],
    createQuery(() =>
      query(
        collection(db, "share_request"),
        where("owner", "==", teamId),
        where("active", "==", true),
        where("type", "==", type),
        orderBy("created_at", "desc")
      )
    ),
    { enabled: !!teamId }
  );
}



// Update an item
export function updateShareRequestBeaconScores(shareId, beaconId, data) {
  return updateDoc(doc(db, `share_request/${shareId}/beacons`, beaconId), { ...data, updated_at: serverTimestamp() });
}

// Subscribe to all beacons under a share request
export function useShareRequestsBeaconParticipantsList(shareId, beaconId, queryEnabled) {

  return useQuery(
    ["beaconParticipants", { beaconId }],
    createQuery(() =>
      query(
        collection(db, `share_request/${shareId}/beacons/${beaconId}/participants`),
        where("exited_date", "!=", false),
        orderBy("entered_date", "desc")
      )
    ),
    { enabled: !!queryEnabled }
  );
  
}


// Subscribe to all beacons under a share request
export function useShareRequestsBeaconsList(shareId) {

  return useQuery(
    ["beacons", { shareId }],
    createQuery(() =>
      query(
        collection(db, `share_request/${shareId}/beacons`),
        //where("revoked", "==", false)
        orderBy("created_at", "desc")
      )
    ),
    { enabled: !!shareId }
  );
}


// Subscribe to all items by owner
export function useShareRequestsAcceptedList(shareId) {
  //console.log(`useShareRequestsAcceptedList v3 shareId is ${shareId}`);

  //console.log(`useShareRequestsAcceptedList v3 ${process.env.REACT_APP_FIREBASE_API_KEY}`);
  //console.log(`useShareRequestsAcceptedList v3 ${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}`);
  //console.log(`useShareRequestsAcceptedList v3 ${process.env.REACT_APP_FIREBASE_PROJECT_ID}`);



  return useQuery(
    ["acceptedaccounts", { shareId }],
    createQuery(() =>
      query(
        collection(db, `share_request/${shareId}/accepted`),
        where("revoked", "==", false)
        //orderBy("accepted_at", "desc")
      )
    ),
    { enabled: !!shareId }
  );
}


// Subscribe to all items by owner
export function useShareResultScoresFromArray(shareId, accountIds) {
  
  var slicedArray = accountIds.slice(0, 30); // limit to 30 items
  slicedArray = slicedArray.length === 0 ? ["dead"] : slicedArray
  
  //console.log(`useShareResultScoresFromArray is ${shareId} ${slicedArray}`);

  //console.log(`share_video_results/${shareId}/scores`);

  //console.log(typeof slicedArray[0]);

  //var tempArr = ["MWSBkqA484bUnqHqnAmtJDWGwBo1", "SqPbkCeCn6NEH0H8CcQmcYR4DGK2"];



  return useQuery(
    ["scoreAccountObjects", { accountIds }],
    createQuery(() =>
      query(
        collection(db, `share_video_results/${shareId}/scores`),
        where("account_id", "in", slicedArray)
        //orderBy("accepted_at", "desc")
      )
    ),
    { enabled: !!accountIds }
  );
}




// Create a new item
export function createShareRequest(data) {
  return addDoc(collection(db, "share_request"), {
    ...data,
    active: true,
    created_at: serverTimestamp(),
  });
}

// Update an item
export function updateShareRequest(id, data) {
  return updateDoc(doc(db, "share_request", id), { ...data, updated_at: serverTimestamp() });
}

// Update an item
export function disableShareRequest(id) {
  return updateDoc(doc(db, "share_request", id), {active: false});
}

// Delete an item
export function deleteShareRequest(id) {
  return deleteDoc(doc(db, "share_request", id));
}


export function useShareVideoResultOnce(id) {
  return useQuery(
    ["share_video_results", { id }],
    // When fetching once there is no need to use `createQuery` to setup a subscription
    // Just fetch normally using `getDoc` so that we return a promise
    () => getDoc(doc(db, "share_video_results", id)).then(format),
    { enabled: !!id }
  );
}

// Subscribe to all items by owner
export function useShareVideoResultScores(id) {
  return useQuery(
    ["shareresultscores", { id }],
    createQuery(() =>
      query(
        collection(db, `share_video_results/${id}/scores`),
        orderBy("created_at", "desc")
      )
    ),
    { enabled: !!id }
  );
}



// Get all accounts by id in list
export function useAcceptedAccountList(idArray) {
  return useQuery(
    ["accounts", { idArray }],
    createQuery(() =>
      query(
        collection(db, `accounts`),
        where("auth_id", "in", idArray)
      )
    ),
    { enabled: !!idArray }
  );
}

// Get all accounts by id in list




/*export function getAcceptedAccountMasterList(shareRequestId) {
  return db
    .collectionGroup(`share_request_ids`)
    .where("id", "==", shareRequestId)
    .get()
    .then(format);
}*/







/**** ITEMS ****/
/* Example query functions (modify to your needs) */

// Subscribe to item data
export function useItem(id) {
  return useQuery(
    ["item", { id }],
    createQuery(() => doc(db, "items", id)),
    { enabled: !!id }
  );
}

// Fetch item data once
export function useItemOnce(id) {
  return useQuery(
    ["item", { id }],
    // When fetching once there is no need to use `createQuery` to setup a subscription
    // Just fetch normally using `getDoc` so that we return a promise
    () => getDoc(doc(db, "items", id)).then(format),
    { enabled: !!id }
  );
}

// Subscribe to all items by owner
export function useItemsByOwner(owner) {
  return useQuery(
    ["items", { owner }],
    createQuery(() =>
      query(
        collection(db, "items"),
        where("owner", "==", owner),
        orderBy("createdAt", "desc")
      )
    ),
    { enabled: !!owner }
  );
}

// Create a new item
export function createItem(data) {
  return addDoc(collection(db, "items"), {
    ...data,
    createdAt: serverTimestamp(),
  });
}

// Update an item
export function updateItem(id, data) {
  return updateDoc(doc(db, "items", id), data);
}

// Delete an item
export function deleteItem(id) {
  return deleteDoc(doc(db, "items", id));
}

/**** HELPERS ****/




// Store Firestore unsubscribe functions
const unsubs = {};

function createQuery(getRef) {
  // Create a query function to pass to `useQuery`
  return async ({ queryKey }) => {
    let unsubscribe;
    let firstRun = true;
    // Wrap `onSnapshot` with a promise so that we can return initial data
    const data = await new Promise((resolve, reject) => {
      unsubscribe = onSnapshot(
        getRef(),
        // Success handler resolves the promise on the first run.
        // For subsequent runs we manually update the React Query cache.
        (response) => {
          const data = format(response);
          if (firstRun) {
            firstRun = false;
            resolve(data);
          } else {
            client.setQueryData(queryKey, data);
          }
        },
        // Error handler rejects the promise on the first run.
        // We can't manually trigger an error in React Query, so on a subsequent runs we
        // invalidate the query so that it re-fetches and rejects if error persists.
        (error) => {
          if (firstRun) {
            firstRun = false;
            reject(error);
          } else {
            client.invalidateQueries(queryKey);
          }
        }
      );
    });

    // Unsubscribe from an existing subscription for this `queryKey` if one exists
    // Then store `unsubscribe` function so it can be called later
    const queryHash = hashQueryKey(queryKey);
    unsubs[queryHash] && unsubs[queryHash]();
    unsubs[queryHash] = unsubscribe;

    return data;
  };
}

// Automatically remove Firestore subscriptions when all observing components have unmounted
client.queryCache.subscribe(({ type, query }) => {
  if (
    type === "observerRemoved" &&
    query.getObserversCount() === 0 &&
    unsubs[query.queryHash]
  ) {
    // Call stored Firestore unsubscribe function
    unsubs[query.queryHash]();
    delete unsubs[query.queryHash];
  }
});

// Format Firestore response
function format(response) {
  // Converts doc into object that contains data and `doc.id`
  const formatDoc = (doc) => ({ id: doc.id, ...doc.data() });
  if (response.docs) {
    // Handle a collection of docs
    return response.docs.map(formatDoc);
  } else {
    // Handle a single doc
    return response.exists() ? formatDoc(response) : null;
  }
}

// React Query context provider that wraps our app
export function QueryClientProvider(props) {
  return (
    <QueryClientProviderBase client={client}>
      {props.children}
    </QueryClientProviderBase>
  );
}
