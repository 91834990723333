import React, {useState, useEffect, useReducer, useRef, useCallback} from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import { InviteReportDemoSection } from "./InviteReportDemoSection"
import { useRouter } from "./../util/router";
import queryString from 'query-string';
import { useAuth } from "./../util/auth";
import { apiRequestWithKey } from "./../util/util"
import { useShareRequestsAcceptedList } from "../util/db";
//import { ShareRequestLiveResults } from "./ShareRequestLiveResults";
//import { ShareRequestVideoResults } from "./ShareRequestVideoResults";
import { Timestamp } from "firebase/firestore";
import capitalize from "@material-ui/core/utils/capitalize";
import ImageIcon from '@material-ui/icons/Image';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CustomCvsButton from "./CustomCvsButton";
import html2canvas from 'html2canvas';
//import { InfluxDB } from "@influxdata/influxdb-client";
import CircularProgress from '@material-ui/core/CircularProgress';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import { InviteReportSummarySection } from "./InviteReportSummarySection";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { redirectToShareResultCheckout } from "./../util/stripe";
import { InviteReportDetailSection } from "./InviteReportDetailSection"
import { useMediaQuery } from "@material-ui/core";
import { ResponsiveCirclePacking } from '@nivo/circle-packing'
import { InviteReportKeyMomentSection } from './InviteReportKeyMomentSection'
import { InviteReportBeaconSection } from "./InviteReportBeaconSection";

const moment = require('moment');

const useStyles = makeStyles((theme) => ({
    cardContent: {
      padding: theme.spacing(3),
    },
    forceH: {
      height: "800px",
    },
    forceScatterH: {
      minHeight: "800px",
    },
    bodyBackground: {
      backgroundImage: `url(./images/bk_high.jpg)`,
      backgroundSize: "cover",
      minHeight: "100vh",
      animation: "fadeInBodyAnimation ease 3s",
      animationIterationCount: "1",
      animationFillMode: "forwards",
    },
    root: {
      //paddingTop: "10px",
      // Add bottom margin if element below
      "&:not(:last-child)": {
        marginBottom: "2rem",
      },
    },
    statbox: {
      padding: '10px',
    },
    white: {
      color: '#ffffff',
    },
  
    white60: {
      color: 'rgba(255, 255, 255, 0.6)',
      
    },
    scoreicon: {
      //paddingTop: '5px',
      height: '100%',
      width: '100%',
    },
    imgheader: {
      // Add bottom margin if element below
      height: '400px'
    },
    actionBar: {
      //boxShadow: `1px 1px 0 0 ${emphasize(theme.palette.background.paper, 0.08)}`,
      textAlign: "right",
    },
    subtitle: {
      maxWidth: 700,
      display: "inline-block",
    },
    gridItem: {
      //boxShadow: `1px 1px 0 0 ${emphasize(theme.palette.background.paper, 0.08)}`,
      textAlign: "left",
    },
    gridItemCenter: {
        textAlign: "center",
      },
    actionBar: {
      //boxShadow: `1px 1px 0 0 ${emphasize(theme.palette.background.paper, 0.08)}`,
      textAlign: "right",
    },
    buffer: {
      marginBottom: "30px",
    },
    doBlur: {
      filter: "blur('25px')",
    },
    eventImage: {
      maxWidth: "100%",
      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
      borderRadius: "10px",
    },
    headline: {
      //fontWeight: "800",
      marginBottom: '0px',
      color: "#ffffff",
    },
    headlineBlack: {
        //fontWeight: "800",
        marginBottom: '0px',
        color: "#000000",
      },
    of100: {
      fontSize: ".8em",
      color: 'rgba(255, 255, 255, 0.6)',
    },
    peopleCard: {
      background: '#062A53',
      borderRadius: '10px',
    },
    button: {
        background: '#000000',
      },
      p: {
        marginBlockStart: '0',
        marginBlockEnd: '0'
      },
      renderMarks: {
        display: 'inline'
      }
  }));
  
/***
 * 
 * https://docs.sheetjs.com/docs/demos/frontend/react
 */





  function InviteReportSection(props) {
    const classes = useStyles();
    const auth = useAuth();
    const router = useRouter();
    const scrollRef = useRef();
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("xs"));

    const overviewRef = useRef();
    const detailRef = useRef();
    const timelineRef = useRef();
  
    const [isPaid, setIsPaid] = useState(false);
    const [isPaidOVERRIDE, setIsPaidOVERRIDE] = useState(false);
    const [isLoadingSummary, setIsLoadingSummary] = useState(true);
    const [isLoadingDetail, setIsLoadingDetail] = useState(true);

    const [inviteSummary, setInviteSummary] = useState(undefined);
    const [downloadData, setDownloadData] = useState(undefined);
    const [inviteSummaryError, setInviteSummaryError] = useState(undefined);
    const [aggWindow, setAggWindow] = useState("15m");
    const [nobuyopen, setNobuyopen] = useState(false);

    const { inviteId, ownerId, ...otherProps } = props;


    const teamDetail = {}; //get team from firebase by ownerid 
    //

    //const [acceptedAccountDetails, setAcceptedAccountDetails] = useState(undefined);

    const { data: acceptedAccountDetails, status: resultsStatus, error: resultsError, } = useShareRequestsAcceptedList(inviteId);
   

    useEffect(() => {

        if (!!inviteId) {
            async function fetchData() {
                let url = `query-event-summary?id=${inviteId}`;
                //console.log(url);
                try {

                  const temp = await apiRequestWithKey(url);  //get inviteSummary from API

                  //console.log("GOT TEMP");

                  //console.log(JSON.stringify(temp));
                  setInviteSummary(temp);

                //  let dseconds = parseInt(temp.duration_seconds);

                setAggWindow(parseInt(temp.duration_seconds) > 4000 ? "30m" : parseInt(temp.duration_seconds) < 300 ? "1m" : "5m");

                
                const pOver = queryString.parseUrl(window.location.href).query['p'];
                if (!!pOver) {
                  if (pOver === process.env.REACT_APP_REPORT_KEY) {
                    setIsPaid(true);
                  } else {
                    setIsPaid(temp.is_paid);
                  }
                } else {
                  setIsPaid(temp.is_paid);
                }

                

                  setIsLoadingSummary(false);
                } catch (error) {

                  console.log("WTAF");

                    console.log(error);
                    setInviteSummaryError(error.message);
                    setIsLoadingSummary(false);
                }
              }
              fetchData();

        }
    },[]);

    useEffect(() => {
        const obj = queryString.parseUrl(window.location.href);
        const pOver = obj.query['p'];
        if (!!pOver) {
          if (pOver === process.env.REACT_APP_REPORT_KEY) {
            setIsPaidOVERRIDE(true);
          }
        }
      },[]);
    
      useEffect(() => {

        if (!!inviteId) {
            async function fetchDownloadData() {
              //console.log("FETCH DETAILED DATA ==>");
                let url = `query-event?id=${inviteId}&agg=1s&detail=true&demo=true`;
                //console.log(url);
                try {

                  const temp = await apiRequestWithKey(url);  //get inviteSummary from API

                  //console.log("GOT TEMP");

                  //console.log(JSON.stringify(temp));
                  setDownloadData(temp);

                } catch (error) {

                    console.log(error);
                    setInviteSummaryError(error.message);
                }
              }
              fetchDownloadData();

        }
    },[]);





      const handleDownloadImage = async (element, name) => {
        //const element: any = overviewRef.current;
        const canvas = await html2canvas(element);
      
        const data = canvas.toDataURL('image/jpg');
        const link = document.createElement('a');
      
        if (typeof link.download === 'string') {
          link.href = data;
          link.download = name + '_image.jpg';
      
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          window.open(data);
        }
      };
    
      const downloadOverviewSection = useCallback(() => {
        handleDownloadImage(overviewRef.current, 'SIX_overview');
      }, [inviteSummary]);

      const downloadDetailSection = useCallback(() => {
        handleDownloadImage(detailRef.current, 'SIX_detail');
      }, [inviteSummary]);
    
      const getFormattedDateString = (dateobject) => {
        if (dateobject && dateobject.seconds != undefined) {
        const timestamp = new Timestamp(dateobject.seconds, dateobject.nanoseconds);
    
        return moment(new Date(timestamp.toDate())).format("MMM D, h:mma")
        } else {
            return moment(dateobject).format("MMM D, h:mma")
        }
    }

    const buy = (quantity) => {
        if (quantity >= 1) {
          redirectToShareResultCheckout(inviteSummary.id, inviteSummary.owner, "pr_tuesday_sr_10", quantity).catch((error) => {
            console.log(error.message);
          });
        } else {
          setNobuyopen(true);
        }
    }
    const handleClose = () => {
        setNobuyopen(false);
      };

    

    return (
        <Section
          bgColor={props.bgColor}
          size={props.size}
          bgImage={props.bgImage}
          bgImageOpacity={props.bgImageOpacity}
          className={classes.bodyBackground}
        >

        <Container ref={overviewRef}>

        { !!isLoadingSummary && 
        <Grid container={true} className={classes.buffer}>
        <Grid item={true} xs={12} md={12} className={classes.gridItemCenter} >    
            <CircularProgress />
            <Typography variant="h6">Loading...</Typography>
        </Grid>
        </Grid>
        }

        {!!inviteSummaryError && 
            <Grid container={true} className={classes.buffer}>
            <Grid item={true} xs={12} md={12} className={classes.gridItemCenter} >    
            <Typography variant="h6">{inviteSummaryError}</Typography>
            </Grid>
            </Grid>
        }


        { !isLoadingSummary && inviteSummary && 

            <>

        <Grid container={true} className={classes.buffer}>
        <Grid item={true} xs={12} md={12} className={classes.gridItem} >
            <Box component="header" className={classes.imgheader} style={{ height: '400px', backgroundImage: `url(${inviteSummary.header_image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            }}>

            <div style={{height: '100%', background: 'rgba(145, 1, 178, 0.2)', padding: '10px'}}>
            <Grid container>
                <Grid item={true} xs={12}   className={classes.actionBar}>
                    <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                    <Button variant="contained" color="secondary" size="medium" className={classes.button} disabled={isPaid ? false : true}>
                        <ImageIcon onClick={downloadOverviewSection}/> 
                    </Button>
                    { /**  !!inviteSummary && 
                    <CustomCvsButton variant="contained" color="secondary" data={new Array(inviteSummary)} filename={`SIX_event_summary_export_${moment().format('YYYYMMDD_HH-mm-ss')}.csv`} delimiter="," disabled={isPaid ? false : true} size="medium" className={classes.button}>
                        <CloudDownloadIcon />
                    </CustomCvsButton>

<img src={`/images/shapes/1_${inviteSummary.shape}.svg`} style={{width: "100%", height: "100%"}} className={classes.xeventImage}/>

                    **/}
                    </ButtonGroup>
                </Grid>
            </Grid>
            </div>

            </Box>

        </Grid>
        { !!inviteSummary && 
        <Grid item={true} xs={12} md={6} className={classes.gridItem} >
            <Box className={classes.root} style={{position:'relative'}}>
            <img src={`/images/shapes/1_${inviteSummary.shape}.svg`} style={{width: "100%", height: "100%"}} className={classes.xeventImage}/>

              { !!inviteSummary && isPaid && inviteSummary.duration_seconds >= 1800 && 

              <InviteReportKeyMomentSection startRange={inviteSummary.start} endRange={inviteSummary.end} inviteSummary={inviteSummary} isPaid={isPaid} />

              }

            </Box>
        </Grid>    
        }

{ !!inviteSummary && 
        <Grid item={true} xs={12} md={6} className={classes.gridItem}  style={{padding: '10px', background: 'rgba(145, 1, 178, 1.0)'}}>
            
              <Grid container={true} direction="column" justifyContent="flex-start" alignItems="stretch" style={{height: '100%'}}>
              <Grid item={true} >

                {inviteSummary.type === "recorded" && (
                <><Typography variant="h6" className={classes.white60}>Recorded</Typography></>
                )}

                {inviteSummary.type === "live" && (
                <><Typography variant="h6" className={classes.white60}>Live Event</Typography></>
                )}


                <Typography variant="h3" className={classes.headline} paragraph={true}>
                {inviteSummary.title}
                </Typography>


                <Typography variant={`body1`}  className={classes.headline} gutterBottom={false} paragraph={true}>
                {getFormattedDateString(inviteSummary.start)} → {getFormattedDateString(inviteSummary.end)}
                </Typography>

                <Typography variant={`body1`}  className={classes.headline} gutterBottom={false} paragraph={true}>
                {inviteSummary.from_name !== "Default" ? inviteSummary.from_name : 'A Friend'}
                </Typography>

              </Grid>

              <Grid item={true} >

              <Grid container={true} >

              <Grid item={true} xs={1} >
              <img src="/images/icons/brain.svg" className={classes.scoreicon}/>
              </Grid>
              <Grid item={true} xs={11} >
              <Typography variant="h4"  className={classes.headline} gutterBottom={false}>
              {inviteSummary.value_score}<Typography variant="caption"  className={classes.of100} gutterBottom={false}>/100</Typography>
              </Typography>
              </Grid>

              <Grid item={true} xs={1} >
              <img src="/images/icons/safety.svg" className={classes.scoreicon}/>
              </Grid>
              <Grid item={true} xs={11} >
              <Typography variant="h4"  className={classes.headline} gutterBottom={false}>
              {inviteSummary.safety_score}<Typography variant="caption"  className={classes.of100} gutterBottom={false}>/100</Typography>
              </Typography>
              </Grid>


              <Grid item={true} xs={1} >
              <img src={`/images/icons/shape-${inviteSummary.shape}.svg`} className={classes.scoreicon}/>
              </Grid>
              <Grid item={true} xs={11} >
              <Typography variant="h4"  className={classes.headline} gutterBottom={false}>
              {capitalize(inviteSummary.shape)}
              </Typography>
              </Grid>


              { !!inviteSummary && !!inviteSummary.audience && !isPaid && 
              <Grid item={true} xs={12} >
              <Button variant="contained" color="secondary" size="medium" className={classes.button} style={{marginTop: '15px'}} onClick={(event) => buy(inviteSummary.audience.completed)}>
                    <AddShoppingCartIcon/> Buy the full report for ${10 * inviteSummary.audience.completed}
              </Button>
              </Grid>
               }

              { !!inviteSummary && !!inviteSummary.audience.completed > 0 && isPaid && !!downloadData && 
              <Grid item={true} xs={12} >
             
              <CustomCvsButton variant="contained" color="secondary" data={downloadData} filename={`SIX_event_detail_1s_export_${moment().format('YYYYMMDD_HH-mm-ss')}.csv`} delimiter="," disabled={isPaid ? false : true} size="medium" className={classes.button}>
                        <CloudDownloadIcon /> DOWNLOAD THE COMPLETE DATASET
                    </CustomCvsButton>


              </Grid>
               }

              </Grid>



              
              </Grid>

              </Grid>


              
          </Grid>
  }
          
          {/** start people stats */}
          { !!inviteSummary && 
          
            

        <Grid container={true} spacing={2} style={{marginTop: '10px'}}>
        <Grid item={true} xs style={{background: 'url("/images/dot.png")'}}>
        </Grid>

        <Grid item={true} xs={3} md={2}>
        <Card className={classes.peopleCard}>
        <CardContent>
        <Typography variant={isSmallScreen ? "caption" : "body1"} className={classes.headline} gutterBottom={false}>ACCEPTED</Typography>
        <Typography variant="h3" className={classes.headline} gutterBottom={false}>{inviteSummary && inviteSummary.audience.accepted >= 0 ? inviteSummary.audience.accepted : '0'}</Typography>
        </CardContent>
        </Card>
        </Grid>

        <Grid item={true} xs={3}  md={2}>
        <Card className={classes.peopleCard}>
        <CardContent>
        <Typography variant={isSmallScreen ? "caption" : "body1"} className={classes.headline} gutterBottom={false}>COMPLETED</Typography>
        <Typography variant="h3" className={classes.headline} gutterBottom={false}>{inviteSummary && inviteSummary.audience.completed >= 0 ? inviteSummary.audience.completed : '0'}</Typography>
        </CardContent>
        </Card>
        </Grid>

        <Grid item={true} xs={3}  md={2}>
        <Card className={classes.peopleCard}>
        <CardContent>
        <Typography variant={isSmallScreen ? "caption" : "body1"} className={classes.headline} gutterBottom={false}>REJECTED</Typography>
        <Typography variant="h3" className={classes.headline} gutterBottom={false}>{inviteSummary && inviteSummary.audience.rejected >= 0 ? inviteSummary.audience.rejected : '0'}</Typography>
        </CardContent>
        </Card>
        </Grid>

        </Grid>
        

          }
{/** End people stats */}

        { !!inviteSummary && isPaid === false && inviteSummary.audience.completed > 0 && 
                  <>
                 
                  <hr className={classes.hrMargin}></hr>
              <Grid container spacing={5} alignItems="stretch" style={{marginTop: '50px'}}>
              <Grid item xs={1} ></Grid>
              <Grid item xs={10} >
              <Typography variant={`h3`} color="textPrimary" gutterBottom={false} style={{textAlign: 'center'}} paragraph={true}>
                  Want the full report?
                  </Typography>

                  <Typography variant={`body1`} color="textPrimary" gutterBottom={false} paragraph={true} style={{textAlign: 'center'}} >
                  With the full report, you can see a timeline graph by minute of the complete experience, download the data for deeper analysis and break the timeline into 5, 15, 30, or 60 minute segments to get a deep understanding of how your audience responded to this experience.
                  </Typography>

                  <Typography variant={`body1`} color="textPrimary" gutterBottom={false}  paragraph={true} style={{textAlign: 'center'}} >
                  Full report is only $10 per completed recipient!
                  </Typography>

                  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <Button variant="contained" color="secondary" size="large" className={classes.button} onClick={(event) => buy(inviteSummary.audience.completed)}>
                      Buy Now for ONLY ${10 * inviteSummary.audience.completed}
                  </Button>
                  </div>

                </Grid> 

                <Grid item xs={1} ></Grid>
              </Grid>
              <hr className={classes.hrMargin}></hr>
              </>
              }      


          {/** begin summary section */}      

          { !!inviteSummary &&  !!isPaid && inviteSummary.value_score > 0 && inviteSummary.audience.completed > 0 && 
              <Grid container spacing={5} alignItems="stretch" style={{marginTop: '50px'}}>
                    <Grid item xs={12}>
                    <Typography variant={`h4`} color="textPrimary" className={classes.headlineBlack} gutterBottom={false} >
                        SUMMARY
                    </Typography>

                {inviteSummary.value_score && inviteSummary.safety_score && inviteSummary && 
                    <InviteReportSummarySection immersion={inviteSummary.value_score} safety={inviteSummary.safety_score} title={inviteSummary.title} type={inviteSummary.type} attendeeCount={inviteSummary.audience.completed}/>
                }
                    </Grid>
                </Grid>
          }
          

        </Grid>    


          {!!isPaid && !!inviteSummary && 
          <InviteReportDetailSection isPaid={isPaid} inviteSummary={inviteSummary} />
          }

          {!!isPaid && !!inviteSummary && !!acceptedAccountDetails && 
          <InviteReportDemoSection startRange={inviteSummary.start} endRange={inviteSummary.end} acceptedAccounts={acceptedAccountDetails} inviteSummary={inviteSummary} isPaid={isPaid} />
          }

        {!!isPaid && !!inviteSummary && 
          <InviteReportBeaconSection inviteSummary={inviteSummary} isPaid={isPaid} />
          }





         </>

        }



        </Container>

        <Dialog
        open={nobuyopen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"No completed participants"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            There were no completed participants for this experience.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          
          <Button onClick={handleClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>

        </Section>
        )






  }

  export default InviteReportSection;


