import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CircularProgress from '@material-ui/core/CircularProgress';
import RoomIcon from '@material-ui/icons/Room';
import { useShareRequestsBeaconParticipantsList, updateShareRequestBeaconScores } from "../util/db";


const useStyles = makeStyles((theme) => ({
  metrics: {
  },
    headline: {
      //fontWeight: "800",
      //color: "#ffffff",
      textAlign: 'center'
    },
    doBlur: {
      filter: "blur(15px)",
    },
    noBlur: {
      //filter: "blur(15px)",
    },
    hrMargin: {
      margin: "30px",
    },
    tileBack: {
        background: '#1CEBFF',//#CDCAFF
    },
    button: {
      background: '#000000',
    },
    scoreicon: {
        //paddingTop: '5px',
        height: '100%',
        width: '100%',
      },
      of100: {
        fontSize: ".8em",
        fontWeight: "bold",
        color: 'rgba(255, 255, 255, 0.6)',
      },
    paper: {
        padding: '6px 16px',
      },
      secondaryTail: {
        backgroundColor: theme.palette.secondary.main,
      },
  }));


  export const InviteReportBeaconTile = (props) => {
    const classes = useStyles();

    const { inviteSummary, beaconItem, isPaid, color, ...otherProps } = props;

    let queryEnabled = false;

    const [executeQuery, setExecuteQuery] = useState(false);    

    const [loading, setLoading] = useState(true);    
    const [immersion, setImmersion] = useState(0);    
    const [safety, setSafety] = useState(0);  
    const { data: beaconsParticipantList, status: resultsStatus, error: resultsError, } = useShareRequestsBeaconParticipantsList(inviteSummary.id, beaconItem.id, executeQuery);

   
    //console.log(beaconsParticipantList);
    //console.log(resultsStatus);
    //console.log(resultsError);

    useEffect(() => {

        if (beaconItem) {
          //console.log("have beaconItem");
          if (beaconItem.hasOwnProperty('immersion')) {
            setImmersion(Math.round(beaconItem.immersion));
            setSafety(Math.round(beaconItem.safety));
            setLoading(false)
          } else {
            //console.log("have beaconItem with NO IMMERSION Prop");
            setExecuteQuery(true);
            queryEnabled = true;
            //loadSummaryDetails()
          }
        }


      }, []);

      useEffect(() => {
        if (!!beaconsParticipantList) {
          console.log("i have beaconsParticipantList");

          if (beaconsParticipantList.length >= 1) {
            console.log(`beaconsParticipantList length == ${beaconsParticipantList.length}`);

            const immersionScores = !!beaconsParticipantList ? beaconsParticipantList.map(item => { return item.immersion; }) : [];
            const safetyScores = !!beaconsParticipantList ? beaconsParticipantList.map(item => { return item.safety; }) : [];

            const avgImm = Math.round(averageArray(immersionScores));
            const avgSafe = Math.round(averageArray(safetyScores));
            setImmersion(avgImm);
            setSafety(avgSafe);

            updateShareRequestBeaconScores(inviteSummary.id, beaconItem.id, {immersion: avgImm, safety: avgSafe });
            setLoading(false)
          } else {
            setLoading(false)
          }
        } 

      }, [beaconsParticipantList]);


      const loadSummaryDetails = () => {
        if (!!beaconsParticipantList) {
          if (beaconsParticipantList.length >= 1) {
            const immersionScores = !!beaconsParticipantList ? beaconsParticipantList.map(item => { return item.immersion; }) : [];
            const safetyScores = !!beaconsParticipantList ? beaconsParticipantList.map(item => { return item.safety; }) : [];

            const avgImm = Math.round(averageArray(immersionScores));
            const avgSafe = Math.round(averageArray(safetyScores));
            setImmersion(avgImm);
            setSafety(avgSafe);

            updateShareRequestBeaconScores(inviteSummary.id, beaconItem.id, {immersion: avgImm, safety: avgSafe });

          }
        }
      }


      const averageArray = (nums) => {
        if (nums.length > 0) {
          return nums.reduce((a, b) => (a + b)) / nums.length;
        } else {
          return 0
        }
    }




    return (
        <>
        
        <Grid key={beaconItem.id} item xs={6} sm={3} className={isPaid === true ? classes.noBlur : classes.doBlur}>
       

            { loading && 
                <CircularProgress color={'secondary'}/>
                
            }

{ loading === false && 

<Grid container={true} style={{background: immersion > 75 ? '#01A878' : immersion < 35 ? '#ff0000' : '#4893FF', borderRadius: '125px', height: '250px', width: '250px'}} direction="column" alignItems="center" justifyContent="center" spacing={0}>
<Grid item={true}  >
<RoomIcon fontSize="large"/>
</Grid>

<Grid item={true} alignContent={"center"} >
<Typography variant="h6"  className={classes.headline} gutterBottom={false}>
{beaconItem.name}
</Typography>
</Grid>

<Grid item={true} >

{ !!immersion && !!safety && 
      <Grid container={true} >
      <Grid item={true} xs={2} ></Grid>
      <Grid item={true} xs={2} >
      <img src="/images/icons/brain.svg" className={classes.scoreicon}/>
      </Grid>
      <Grid item={true} xs={6} >
      <Typography variant="h4"  className={classes.metrics} gutterBottom={false}>
      {immersion}<Typography variant="caption"  className={classes.of100} gutterBottom={false}>/100</Typography>
      </Typography>
      </Grid>
      <Grid item={true} xs={2} ></Grid>

      <Grid item={true} xs={2} ></Grid>
      <Grid item={true} xs={2} >
      <img src="/images/icons/safety.svg" className={classes.scoreicon}/>
      </Grid>
      <Grid item={true} xs={6} >
      <Typography variant="h4"  className={classes.metrics} gutterBottom={false}>
      {safety}<Typography variant="caption"  className={classes.of100} gutterBottom={false}>/100</Typography>
      </Typography>
      </Grid>
      <Grid item={true} xs={2} ></Grid>

      </Grid>
}

</Grid>



</Grid>
  }


           

                    
        </Grid>

        </>
    )


  };